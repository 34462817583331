import axios from "axios";
import store from "./store/index";
import Element from "element-ui";
import qs from "qs"

const local_url = "http://localhost:8080/dscs";
const remote_url = "http://8.142.92.213/data/dscs";
const hl_url = "https://dev.chinafilm-hualong.com/data/dscs";
axios.defaults.baseURL = hl_url;

const request = axios.create({
    timeout: 20000,
    headers: {
        'Content-Type': "application/json; charset=utf-8"
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
    }
})

request.interceptors.request.use(
    config => {
        config.headers['Authorization'] = localStorage.getItem("dscsToken")
        store.commit("updateApiCount", "add");
        return config
    },
    error => {
        store.commit("updateApiCount", "sub");
        Promise.reject(error);
    }
)

request.interceptors.response.use(
    response => {
        store.commit("updateApiCount", "sub");
        // console.log(response);
        let res = response.data;
        if(res && res.code){
            //json数据
            if (res.code === 200) {
                let jwt = localStorage.getItem("dscsToken");
                if(jwt){
                    return res;
                }else{
                    return response;
                }
            } else if(res.code === 400) {
                Element.Message.error(res.msg ? res.msg :'操作失败')
                return Promise.reject(response.data.msg)
            } else if(res.code === 500){
                Element.Message.error(res.msg ? res.msg :'系统异常')
                return Promise.reject(response.data.msg)
            }
        }else{
            return response;
        }
    },
    error => {
        store.commit("updateApiCount", "sub");
        if(error.response){
            // console.log(error.response);
            if (error.response.data && error.response.data.msg) {
                error.message = error.response.data.msg
            }

            if (error.response.status === 401) {
                Element.MessageBox.confirm(
                    '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    store.dispatch('logOut').then(() => {
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            }else if(error.response.status === 400 || error.response.status === 500){
                // console.log(error.response);
                if(error.response.data && error.response.data.stackTrace){
                    console.log(error.response.data.stackTrace)
                }
            }
            Element.Message.error(error.message, {duration: 3000});
        }else if(error.request){
            Element.MessageBox({message: "无法连接服务器", title: "提示",showClose:false, type: "error"}).then(()=>{
                store.dispatch('logOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            });
        }else{
            Element.Message.error(error.message, {duration: 3000});
            console.log(error.toJSON());
        }
        return Promise.reject(error)
    }
)

export default request